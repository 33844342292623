@import "styles/variable";

.parking {
  padding-top: 40px!important;
  margin-bottom: 60px!important;

  .content {
    display: flex;
    position: relative;
    top: 80px;
    transition: 1s;
    opacity: 0;
  }
  .animation {
    top: 0;
    transition: 1s;
    opacity: 1;
  }
  .item {
    position: relative;
    width: 50%;
    &:nth-of-type(1){
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #1e324b;
      overflow: hidden;
      div {
        width: 100%;
      }
    }
  }
  .img {
    //width: 100%;
    height: 100%;
    //object-fit: cover;
  }
  .text {
    padding: 31px 38px;
    background: linear-gradient(341.93deg, #7C8CB6 3.99%, #7F8CB0 52.17%, #63729A 119.62%);
    mix-blend-mode: multiply;
    color: #fff;
    p {
      font-weight: 300;
    }
  }
  .title {
    font-size: 30px;
    font-family: $font-geo;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 12px;
    line-height: 1;
  }
  .button {
    margin-top: 59px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
}

@media all and (max-width: 1168px) {
  .parking {
    .title {
      font-size: 24px;
    }
  }
}

@media all and (max-width: 940px) {
  .parking {
    padding-top: 24px!important;
    .title {
      font-size: 18px;
    }
    p {
      font-size: 11px;
    }
    .button {
      margin-top: 18px;
    }
  }
}

@media all and (max-width: 700px) {
  .parking {
    padding: 0!important;
    margin-bottom: 0!important;
    .content {
      flex-wrap: wrap;
    }
    .item {
      width: 100%;
      &:nth-of-type(1){
        height: auto;
        display: flex;
        justify-content: center;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .animation {
      transition: 0.3s;
    }
  }
}

@media all and (max-width: 420px) {
  .parking .button {
    width: 100%;
    text-align: center;
  }
}